import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Link } from 'gatsby';
import React from 'react';

export interface BreadcrumbData {
    title: string;
    url: string;
}

export const Breadcrumbs = ({ data }: { data: BreadcrumbData[] }) => {
    const lastCrumb = data[data.length - 1];

    return (
        <div className='w-full h-14 md:h-auto flex flex-row md:flex-col items-center md:items-start py-0 md:p-4 md:pb-0 md:my-0 px-16 md:mx-auto text-xl tracking-wider'>
            <div className='flex flex-row'>
                {
                    data.map((crumb, idx) => {
                        if (idx < data.length - 1) {
                            return (
                                <div key={crumb.title} className='opacity-80 flex items-center'>
                                    <Link className="inline-block mr-0.5 hover:underline" to={crumb.url}>{crumb.title}</Link>
                                    <ChevronRightIcon className="inline-block w-4 h-4 relative top-[2px]" />
                                </div>
                            )
                        }
                    })
                }

            </div>
            <Link className='text-xl md:text-4xl tracking-wider font-semibold hover:underline md:-mt-1' to={lastCrumb.url}>{lastCrumb.title}</Link>
            <div className="w-full mx-auto my-2 hidden md:block">
                <div className="h-[1px] bg-brown-900" />
            </div>
        </div>
    )
}
