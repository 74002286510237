import { Transition } from '@headlessui/react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Button, ButtonTypes } from '../components/Button';
import { Layout } from "../components/Layout";
import SEOHeader from "../components/SEOHeader";

const MediaPage = () => {
    return (
        <Layout>
            <SEOHeader title="Media" description="Audio and video produced by or featuring Jessica Fox." />
            <div className='flex flex-col w-full mx-auto'>
                <div className='w-full max-w-7xl mx-auto'>
                    <Breadcrumbs
                        data={[{ title: "Home", url: "/" }, { title: "Media", url: "/media" }]}
                    />
                </div>
                <div className='flex justify-center w-full flex-col md:flex-row flex-wrap mb-4 gap-4 md:gap-8 p-8 text-white'>
                    <Transition
                        className='w-full max-w-2xl rounded-md bg-neutral-800'
                        appear={true}
                        show={true}
                        enter="ease-out duration-500"
                        enterFrom="opacity-0 scale-95 translate-y-4"
                        enterTo="opacity-100 scale-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <h2 className='text-2xl font-semibold p-4 rounded-t-md bg-neutral-900'>Jessica Fox - Solitude</h2>
                        <iframe className='w-full h-96 max-h-full' src="https://www.youtube.com/embed/6ENxEf-DT3A" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                        <div className='p-4 rounded-b-md bg-neutral-900'>
                            <p>Description of Jessica Fox - Solitude (Originally Composed by Duke Ellington)</p>
                        </div>
                    </Transition>

                    <Transition
                        className='w-full max-w-2xl rounded-md bg-neutral-800'
                        appear={true}
                        show={true}
                        enter="ease-out duration-500"
                        enterFrom="opacity-0 scale-95 translate-y-4"
                        enterTo="opacity-100 scale-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <h2 className='text-2xl font-semibold p-4 rounded-t-md bg-neutral-900'>Jessica Fox - Meditation</h2>
                        <iframe className='w-full h-96 max-h-full' src="https://www.youtube.com/embed/I7jZhU-2t6Y" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                        <div className='p-4 rounded-b-md bg-neutral-900'>
                            <p>Originally composed by Antônio Carlos Jobim and Newton Mendonça</p>
                        </div>
                    </Transition>

                    <Transition
                        className='w-full max-w-2xl rounded-md bg-neutral-800'
                        appear={true}
                        show={true}
                        enter="ease-out duration-500"
                        enterFrom="opacity-0 scale-95 translate-y-4"
                        enterTo="opacity-100 scale-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <h2 className='text-2xl font-semibold p-4 rounded-t-md bg-neutral-900'>Jessica Fox - Almost Like Being In Love</h2>
                        <iframe className='w-full h-96 max-h-full' src="https://www.youtube.com/embed/N79jdNk1n-Y" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                        <div className='p-4 rounded-b-md bg-neutral-900'>
                            <p>Originally composed by Frederick Loewe and Alan Jay Lerner</p>
                        </div>
                    </Transition>

                    <Transition
                        className='w-full max-w-2xl rounded-md bg-neutral-800'
                        appear={true}
                        show={true}
                        enter="ease-out duration-500"
                        enterFrom="opacity-0 scale-95 translate-y-4"
                        enterTo="opacity-100 scale-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <h2 className='text-2xl font-semibold p-4 rounded-t-md bg-neutral-900'>White Horse Live #68</h2>
                        <iframe className='w-full h-96 max-h-full' title="vimeo-player" src="https://player.vimeo.com/video/535389439?h=3c13125f6f" allowFullScreen={true}></iframe>
                        <div className='p-4 rounded-b-md bg-neutral-900'>
                            <p>Description of White Horse Live #68</p>
                        </div>
                    </Transition>

                    <Transition
                        className='w-full max-w-2xl rounded-md bg-neutral-800'
                        appear={true}
                        show={true}
                        enter="ease-out duration-500"
                        enterFrom="opacity-0 scale-95 translate-y-4"
                        enterTo="opacity-100 scale-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <h2 className='text-2xl font-semibold p-4 rounded-t-md bg-neutral-900'>White Horse Live #155</h2>
                        <iframe className='w-full h-96 max-h-full' src="https://www.youtube.com/embed/_1Lt2osD_yk" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                        <div className='p-4 rounded-b-md bg-neutral-900'>
                            <p>Description of White Horse Live #155</p>
                        </div>
                    </Transition>

                    <Transition
                        className='w-full max-w-2xl rounded-md bg-neutral-800'
                        appear={true}
                        show={true}
                        enter="ease-out duration-500"
                        enterFrom="opacity-0 scale-95 translate-y-4"
                        enterTo="opacity-100 scale-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <h2 className='text-2xl font-semibold p-4 rounded-t-md bg-neutral-900'>Live recording of <a className='underline' href="https://instagram.com/stateparkranger" target='_blank'>State Park Ranger</a></h2>
                        <iframe src="https://drive.google.com/file/d/1-VWQu7jl19jDp_Nq4u0ZzF6Z0ACZ4Rdu/preview" className='w-full h-48 max-h-full' />
                        <div className='p-4 rounded-b-md bg-neutral-900'>
                            <p>Live recording of <a className='underline' href="https://instagram.com/stateparkranger" target='_blank'>State Park Ranger</a> performing with <a className='underline' href="https://www.youtube.com/watch?v=GllizQv-4ko" target='_blank'>Claire Hoke</a> and <a className='underline' href="https://www.instagram.com/phiabird" target='_blank'>Sophia Corinne</a> at <a className='underline' href="https://www.instagram.com/openfolkavl" target='_blank'>Open Folk</a></p>
                        </div>
                    </Transition>
                </div>
            </div>
        </Layout>
    )
}

export default MediaPage;
